import { RedemptionService } from 'src/app/services/redemption/redemption.service';
import { PushNotificationsManagerService } from './../push-notifications-manager/push-notifications-manager.service';
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { RemoteConfigService } from "src/app/services/remote-config/remote-config.service";
import { RouterService } from "src/app/services/router/router.service";
import { TagsService } from "src/app/services/tags/tags.service";
import {
  Plugins,
  PushNotification
} from '@capacitor/core';
import { StorageService } from 'src/app/services/storage/storage.service';
import {Location} from '@angular/common';
import { ShoppingCartService } from 'src/app/services/shopping-cart/shopping-cart.service';
const { PushNotifications } = Plugins;
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  @Input() page: string;
  @Input() image: string;
  @Input() title: string;
  @Input() enableMenu: boolean = false;
  @Input() enableChat: boolean = false;
  @Input() enableLine: boolean = false;
  @Input() enableHref: boolean = true;
  @Input() enableRedemption: boolean = false;
  @Input() enableCart: boolean = true;

  @Input() set isRedemptionActive(value){
    this.isActive = value;
    this.iconLockRedemption = value ? '../../../assets/icons/home/open_lock.svg' : '../../../assets/icons/home/close_lock.svg';
  };
  @Input() noButtons: boolean = false;
  @Output() onBack: EventEmitter<any> = new EventEmitter();
  isActive = false;
  phoneContact:string;
  apiWhatsapp:string = 'https://api.whatsapp.com/send?phone='
  iconLockRedemption = '';
  notificationUnViewed : string| number = 0;
  productsShoppingCart = 0;
  constructor(
    private tagsService: TagsService,
    private remoteConfig:RemoteConfigService,
    private router: RouterService,
    private modalController: ModalController,
    private redemptionService: RedemptionService,
    private pushNotificationsManagerService: PushNotificationsManagerService,
    private location: Location,
    private shoppingCartService: ShoppingCartService,
    private storageServices: StorageService
    ) {
    }
    
    async ngOnInit() {  
      const claims = await this.storageServices.get("gnx-claims");
      this.remoteConfig.getInfoApp().toPromise().then(( res:any ) => {
        if(claims['programs'][0]=="companies/bancolombia_sa/programs/sumaton"){
          this.phoneContact = res["phone-sumaton"];
          return this.apiWhatsapp = `${this.apiWhatsapp}${this.phoneContact}&text=¡Hola!`;
        }else{
          this.phoneContact = res["phone-reconocerte"];
          return this.apiWhatsapp = `${this.apiWhatsapp}${this.phoneContact}&text=¡Hola!`;
        }
      } );

      this.pushNotificationsManagerService.numberViewed$.subscribe((element)=>{
        console.log("++","11");
        this.notificationUnViewed = element; 
      })
      this.shoppingCartService.numberProducts$.subscribe((element)=>{
        console.log("++","22");
        this.productsShoppingCart = element; 
      })

      // await this.remoteConfig.getInfoApp().toPromise().then(( res:any ) => {
      //   console.log("++",res);
      //   this.phoneContact = res["phone-contact"];
      // } );


  }

  ionViewDidEnter(){
    this.pushNotificationsManagerService.numberViewed$.subscribe((element)=>{
      console.log("++","11");
      this.notificationUnViewed = element; 
    })
    this.shoppingCartService.numberProducts$.subscribe((element)=>{
      console.log("++","22");
      this.productsShoppingCart = element; 
    })
  }

  support_chat() {
    this.tagsService.setEventTag("home_page", "support_clicked", true, true);
  }

  back(page){
    if(page == ""){
      this.location.back();
    } else {
      this.router.redirect(page)
    }
    this.modalController.dismiss(null, null, "modal-terms-conditions");
    this.modalController.dismiss(null, null, "modal-privacy-policies");
    // this.onBack.emit();
  }

  async openRedemptionInfo(){    
    if(this.isActive) return;
    this.redemptionService.openModal('header');
  }

  openShoppingCart(){
    this.router.redirectRoot("/app/shopping-cart")
  }

  get setRouterSize(){
    const current = this.router.getCurrentUrl();
    const routesWithOtherSize = [
      '/page-terms-conditions',
      '/privacy-policies'
    ]
   return routesWithOtherSize.includes(current)
  }
}
